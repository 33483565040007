import * as _ from 'lodash';

const hasUser = {
  computed: {
    definedRoles() {
      return this.$store.getters['roles/getAll'];
    },
    definedRolesMeta() {
      return this.$store.getters['roles/getAllMeta'];
    },
  },
  data() {
    return {
      errorRoles: null,
      isLoadingRoles: false,
    };
  },
  created() {
    console.log('hasUser.js created()');
    this.fetchRolesData();
  },
  methods: {
    fetchRolesData() {
      // #TODO lock the page while loading
      this.errorRoles = null;
      this.isLoadingRoles = true;

      const payload = {
        params: {
          order_by: 'name',
          order_dir: 'ASC',
        },
      };

      console.log('hasUser.js methods fetchRolesData() before :payload', payload);

      this.$store
        .dispatch('roles/getAll', payload)
        .catch((error) => {
          this.errorRoles = this.$errorProcessor(error);
        })
        .then(() => {
          console.log('hasUser.js methods fetchRolesData() then');
          this.isLoadingRoles = false;
        });
    },
    getRoles(roles) {
      return roles.length ? roles.map((role) => _.upperFirst(role.name)) : ['None'];
    },
  },
};

// leave the export, even if you don't use it
export default hasUser;
