<template>
  <form @submit.prevent="handleSubmit">
    <FormError v-if="error" :error="error" />

    <fieldset :disabled="isLocked">
      <div class="form-group">
        <div class="form-check" v-for="vRole in definedRoles" :key="`role-${vRole.id}`">
          <input
            v-model="role"
            type="radio"
            :id="`role-radio-${vRole.id}`"
            class="form-check-input"
            name="role"
            :value="vRole.id"
          />
          <label class="form-check-label" :for="`role-radio-${vRole.id}`">
            {{ upperCase(vRole.name) }}
          </label>
        </div>
      </div>
    </fieldset>

    <div class="form-row justify-content-between">
      <button type="submit" class="btn btn-success" :disabled="isLocked">
        <font-awesome-icon icon="save" /> Save
      </button>
      <button type="button" class="btn btn-outline-danger" :disabled="isLocked" @click="modalClose">
        <font-awesome-icon icon="window-close" /> Cancel
      </button>
    </div>
  </form>
</template>

<script>
/*global $*/
import * as _ from 'lodash';
import helpers from '../../mixins/helpers';
import formHelpers from '../../mixins/formHelpers';
import FormError from './FormError';

export default {
  name: 'UserPermissionsForm',
  mixins: [formHelpers, helpers],
  components: { FormError },
  props: {
    definedRoles: {
      type: Array,
      required: true,
    },
    modal: {
      type: String,
      required: false,
    },
    user: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      error: null,
      isLocked: false,
      allowedFields: ['role_id'],
      role: null,
      userRoleId: null,
      userRoleName: null,
    };
  },
  created() {
    const userRoles = this.user.roles;
    console.log('UserPermissionsForm created() :userRoles', userRoles);

    if (userRoles.length) {
      const uRole = userRoles[0];
      const dRole = this.$store.getters['roles/getRoleByName'](uRole.name);
      console.log('UserPermissionsForm created() :uRole :dRole', { uRole, dRole });

      if (dRole) {
        this.role = dRole.id;
      }

      this.userRoleId = this.$store.getters['roles/getRoleByName'](uRole.name).id;
      this.userRoleName = uRole.name;
    }
  },
  methods: {
    handleSubmit() {
      console.log('UserPermissionsForm methods handleSubmit()');
      // Lock the buttons
      this.error = null;
      this.isLocked = true;

      const addPayload = {
        formData: this.toFormData(this.prepareData()),
        options: {
          id: this.user.user_id,
          method: 'POST',
        },
      };

      console.log(
        'UserPermissionsForm methods handleSubmit() addRoles() before :addPayload',
        addPayload
      );

      // Send to action
      this.$store
        .dispatch('users/addRoles', addPayload)
        .then((response) => {
          // on success, close modal, unlock buttons
          console.log('UserPermissionsForm methods handleSubmit() addRoles() then', response);
          this.modalClose();
        })
        .catch((error) => {
          // on error, highlight errors
          console.error('UserPermissionsForm methods handleSubmit() addRoles() catch', error);
          this.error = this.$errorProcessor(error);
        })
        .finally(() => {
          this.isLocked = false;
        });
    },
    modalClose() {
      // #TODO clear the form
      // if we have a modal parent, close it
      if (this.modal) {
        $(this.modal).modal('hide');
      }
    },
    prepareData() {
      return { role_id: this.role };
    },
    unlockForm() {
      this.isLocked = false;
    },
    upperCase(string) {
      return _.upperFirst(string);
    },
  },
};
</script>
